import React, { useEffect } from "react";
import vec from "../../components/img/Icon.svg";
import vec1 from "../../components/img/cancel.svg";

const Modal = ({ show, setShow }) => {
  const ShowModal = () => {
    setShow(false);
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);
  return (
    <div>
      <div className={show ? "modalparent max-w-[1600px] m-[auto]" : "hidden"}>
        <div className="modalsvg  relative h-[50%] max-h-[450px] w-[38%] p-[3rem] max-sm:px-[4%] flex flex-col gap-y-4 max-sm:w-[90%] max-sm:h-auto">
          <h2 className=" text-white text-[1.2rem] w-[60%] max-sm: mt-[.1rem] max-sm:w-full">Need help with your rent ?</h2>
          <p className="text-white w-[65%] text-[.9rem] max-sm:w-full max-sm:text-[1rem]">
            Renew your existing rent, discover amazing properties or upload your choice property and we help finance it{" "}
          </p>
          <div className="mt-[1.5rem] flex gap-x-3 max-sm:flex-col max-sm:gap-y-2">
            <a
              href={localStorage.getItem("tk") ? "https://account.myrentease.co/directrent/newrent" : "https://account.myrentease.co"}>
              <button className="bg-[#fff] text-[#287BDC] text-[1rem] font-semibold py-3 px-4 rounded-md cursor-pointer">Renew your rent</button>
            </a>
            <a className="flex items-center gap-x-3 text-white text-[1.2rem] cursor-pointer max-sm:w-full" href="https://explorer.myrentease.co">
              <button className="flex items-center gap-x-3 text-white text-[1rem] cursor-pointer max-sm:w-full">
                <span> View Properties</span>
                <img src={vec} alt="" />
              </button>
            </a>
          </div>
          <img onClick={ShowModal} className="absolute top-[-7px] right-[-15px] cursor-pointer" src={vec1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Modal;
