import React from "react";
import logo from "../img/logo.png";
import facebook from "../img/facebook.svg";
import twitter from "../img/twitter.svg";
import youtube from "../img/youtube.svg";
import instagram from "../img/instgram.svg";
import { HashLink as Link } from "react-router-hash-link";

const FooterPage = () => {
   return (
      <div>
         <div className=" py-11 px-14 flex w-full bg-[#000000] text-white justify-between border-b-2 mt-[10%] max-md:hidden">
            <div className="max-lg:w-[190px]">
               <div className="w-[195px]">
                  <img alt="" src={logo} />
               </div>
               <p className="text-[14px] mt-3 text-[#6E6E74] max-lg:texe-[14px]">
                  Rentease offers a reliable solution <br></br> for stress-free and affordable
                  <br></br> housing options of your preference<br></br> We offer Flexible
                  installment payments <br></br> and diverse selections of properties<br></br> from
                  locations accross Nigeria
               </p>
            </div>
            <div className="flex flex-col gap-y-2 pt-[20px]">
               <h1 className=" font-extrabold text-[17px]">Company</h1>
               <ul className=" grid gap-y-3">
                  <li>
                     <Link smooth to="#explore">
                        <p className="text-[14px] cursor-pointer font-extralight">About Us</p>
                     </Link>
                  </li>
                  <li>
                     <a target="_self" href="/faq">
                        <p className="text-[14px] cursor-pointer font-extralight">FAQ</p>
                     </a>
                  </li>
               </ul>
            </div>
            <div className="flex flex-col gap-y-2 pt-[20px]">
               <h1 className=" font-extrabold text-[17px]">Support</h1>
               <ul className=" block gap-y-2">
                  <li>
                     <a target="_self" href="https://rentease.tawk.help/">
                        <p className="text-[14px] cursor-pointer font-extralight">Help Center</p>
                     </a>
                  </li>
               </ul>
            </div>
            <div className="flex flex-col gap-y-2 pt-[20px]">
               <h1 className=" font-extrabold text-[17px]">Legal</h1>
               <ul className=" grid gap-y-3">
                  <li>
                     <a href="/Cookie">
                        <p className="text-[14px] cursor-pointer font-extralight">Cookies Policy</p>
                     </a>
                  </li>
                  <li>
                     <a target="_self" href="/Privacy">
                        <p className="text-[14px] cursor-pointer font-extralight">Privacy Policy</p>
                     </a>
                  </li>
                  <li>
                     <a target="_self" href="/Termsofservice">
                        <p className="text-[14px] cursor-pointer font-extralight">
                           Terms of Service
                        </p>
                     </a>
                  </li>
               </ul>
            </div>
            <div className=" pt-[20px] w-[11%] max-lg:w-[18%]">
               <h1 className=" font-extrabold text-[17px]">Socials</h1>
               <div className="flex w-full mt-2 justify-between">
                  <span>
                     <img alt="" src={facebook} className=" cursor-pointer "></img>
                  </span>
                  <a href="https://twitter.com/myrentease">
                     <img alt="" src={youtube} className=" cursor-pointer "></img>
                  </a>
                  <span>
                     <img alt="" src={twitter} className=" cursor-pointer "></img>
                  </span>
                  <a href="https://www.instagram.com/myrentease/">
                     <img alt="" src={instagram} className=" cursor-pointer "></img>
                  </a>
               </div>
            </div>
         </div>
         <div className="w-full text-center text-[#ffffff] px-14 h-10vh bg-[#000000] max-md:hidden">
            <div className="py-[5%]">
               <p className="text-[20px]">@ 2023 All rights reserved</p>
            </div>
         </div>
         {/*mobile view*/}
         <div className=" py-11 px-2 grid w-full bg-[#000000] text-white justify-between border-b-2 mt-[10%] md:hidden max-md:grid max-md:justify-center">
            <div className="">
               <div className="w-[175px]">
                  <img alt="" src={logo}></img>
               </div>
               <p className="text-[14px] mt-3 text-[#6E6E74] max-lg:texe-[14px]">
                  Rentease offers a reliable solution <br></br> for stress-free and affordable
                  <br></br> housing options of your preference<br></br> We offer Flexible
                  installment payments <br></br> and diverse selections of properties<br></br> from
                  locations accross Nigeria
               </p>
            </div>
            <div className="flex w-full justify-between">
               <div className=" pt-[20px] leading-8 w-[50%]">
                  <h1 className=" font-extrabold text-[17px]">Company</h1>
                  <ul className=" mt-1 gap-y-2">
                     <li>
                        <Link smooth to="#explore">
                           <p className="text-[14px] cursor-pointer font-extralight">About Us</p>
                        </Link>
                     </li>
                     <li>
                        <a target="_self" href="/faq">
                           <p className="text-[14px] cursor-pointer font-extralight">FAQ</p>
                        </a>
                     </li>
                  </ul>
               </div>
               <div className=" pt-[20px] leading-8">
                  <h1 className=" font-extrabold text-[17px]">Support</h1>
                  <ul className=" mt-1 gap-y-2">
                     <li>
                        <a target="_self" href="https://rentease.tawk.help/">
                           <p className="text-[14px] cursor-pointer font-extralight">Help Center</p>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
            <div className=" pt-[20px] leading-8">
               <h1 className=" font-extrabold text-[17px]">Legal</h1>
               <ul className=" mt-1 gap-y-2">
                  <li>
                     <a href="/Cookie">
                        <p className="text-[14px] cursor-pointer font-extralight">Cookies Policy</p>
                     </a>
                  </li>
                  <li>
                     <a target="_self" href="/Privacy">
                        <p className="text-[14px] cursor-pointer font-extralight">Privacy Policy</p>
                     </a>
                  </li>
                  <li>
                     <a target="_self" href="/Termsofservice">
                        <p className="text-[14px] cursor-pointer font-extralight">
                           Terms of Service
                        </p>
                     </a>
                  </li>
               </ul>
            </div>
            <div className=" pt-[20px] w-[50%]">
               <h1 className=" font-extrabold text-[17px] w-[50%]">Socials</h1>
               {/* <div><button className='mt-[7%] border-2 rounded-md bg-[#ffffff] h-[46px] text-center text-[#000000] border-transparent w-full cursor-pointer flex px-2 py-1 justify-between'><img src={playstore}></img><div className='flex flex-col'><span className='text-[9px]'>GET IT ON</span><p className='text-[13px] font-bold'>Google Play</p></div></button></div>
                <div>
                    <button className='mt-[7%] border-2 rounded-md bg-[#ffffff] h-[46px] text-center text-[#000000] border-transparent w-full cursor-pointer flex px-2 py-1 justify-between'><img src={apple}></img><div className='flex flex-col'><span className='text-[9px]'>Download on the</span><p className='text-[13px] font-bold'>App store</p></div></button>
                </div> */}
               <div className="flex w-full mt-2 justify-between">
                  <span>
                     <img alt="" src={facebook} className=" cursor-pointer "></img>
                  </span>
                  <a href="https://twitter.com/myrentease">
                     <img alt="" src={twitter} className="cursor-pointer "></img>
                  </a>
                  <span>
                     <img alt="" src={youtube} className=" cursor-pointer "></img>
                  </span>
                  <a href="https://www.instagram.com/myrentease/">
                     <img alt="" src={instagram} className="cursor-pointer "></img>
                  </a>
               </div>
            </div>
         </div>
         <div className="w-full text-center text-[#ffffff] px-14 h-10vh bg-[#000000] sm:hidden">
            <div className="py-[10%]">
               <p className="text-[20px]">@ 2023 All rights reserved</p>
            </div>
         </div>
      </div>
   );
};

export default FooterPage;
