import React from 'react'
import VectorT  from "../img/Vector2.png";
import ott1  from "../img/ott.svg";
import ott  from "../img/ott.png";
import VectorThree from "../img/Vector3.png";
import VectorF from "../img/Vector4.png";
const HowItWorks = () => {
  return (
    <div>
        <div className='w-full px-14 mt-[10%] max-lg:hidden'>
            <div className='w-full text-center'>
                <h2 className='text-[45px] font-extrabold text-[#000000]'>How it works</h2>
                <p className='text-[29px] mt-2'>User-friendly guide to enrolling with us.</p>
            </div>
            <div className='flex flex-row justify-between w-full mt-[7%]'>
                <div className='w-[40%] grid '>
                    <div className='w-full h-[120px] py-2 flex border-[#000000] bg-[#000000] rounded-lg px-3'>
                        <div className='m-3'>
                            <img src={VectorT} className='w-[40px] h-auto' alt=''></img>
                        </div>
                        <div className='m-2'>
                            <h2 className='text-[#ffff] text-[22px] font-bold'>Apply & Explore</h2>
                            <p className='text-[#ffff] text-[14px]'>Become a member and explore great<br></br>& amazing listings</p>
                        </div>
                    </div>
                    <div className='bg-black bg-[url("components/img/lapi.png")] bg-no-repeat bg-cover relative w-full h-[320px] mt-[17%] rounded-lg'>
                        <a rel="noreferrer"  target='_blank' href='https://account.myrentease.co/'><button className='mt-5 absolute bg-[#ffff] border-2 border-transparent w-[152px] h-[50px] text-[#287BDC] text-[16px] rounded-md cursor-pointer right-8 bottom-8'>Get started</button></a>
                    </div>
                </div>
                <div className='h-auto'>
                    <div className='w-[73px] h-[20%]'>
                        <img src={ott} alt=''></img>
                    </div>
                </div>
                <div className='pt-[20%] h-auto relative'>
                    <div className='w-[500px] py-2 flex border-trasnparent bg-[#2B7CDC] rounded-lg px-3'>
                        <div className='m-3'>
                            <img src={VectorThree} className='w-[100px] h-auto' alt=''></img>
                        </div>
                        <div className='m-2'>
                            <h2 className='text-[#ffff] text-[22px] font-bold'>Confirm Eligibility</h2>
                            <p className='text-[#ffff] text-[14px]'>Submit your KYC and Work Information, if approved you are eligible for monthly property rental on Rentease</p>
                        </div>
                    </div>
                    <div className='w-[500px] py-2 flex border-trasnparent bg-[#2B7CDC] rounded-lg px-3 mt-[13%]'>
                        <div className='m-3'>
                            <img src={VectorF} className='w-[150px]  h-auto' alt=''></img>
                        </div>
                        <div className='m-2'>
                            <h2 className='text-[#ffff] text-[22px] font-bold'>Book an appointment</h2>
                            <p className='text-[#ffff] text-[14px]'>Schedule a meeting with an Agent, Inspect property and Request for property rental, pay 35% of the property rent excluding agency and caution fees and move in to the apartment, then pay the balance over a `10 month period.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*mobile view */}
        <div className='w-full px-5 mt-[10%] lg:hidden'>
            <div className='w-full text-center leading-6'>
                <h2 className='text-[20px] font-extrabold text-[#000000]'>How it works</h2>
                <p className='text-[12px] mt-2'>User-friendly guide to enrolling with us.</p>
            </div>
            <div className='flex flex-row mt-[10%] max-md:flex max-md:justify-center max-md:px-[7%]'>
                <div className='h-auto'>
                     <div className='w-[56px] h-[10%]'>
                           <img src={ott1} alt=''></img>
                        </div>
                </div>
                <div className='w-full'>
                    <div className='w-full grid gap-y-10 pl-5'>
                       <div className='w-[248px] h-[100px] py-2 flex border-[#000000] bg-[#000000] rounded-lg px-3 max-sm:w-[248px] max-lg:w-[60%] max-lg:h-auto'>
                           <div className='m-3'>
                               <img src={VectorT} className='w-[40px] h-auto' alt=''></img>
                           </div>
                            <div className='m-2'>
                                <h2 className='text-[#ffff] text-[20px] font-bold'>Register & Explore</h2>
                                <p className='text-[#ffff] text-[14px]'>Become a member and explore great<br></br>& amazing listings</p>
                           </div>
                        </div>
                            <div className='w-[248px] h-[100px] py-2 flex border-trasnparent bg-[#2B7CDC] rounded-lg px-3 max-md:w-[60%] max-sm:w-[248px] max-lg:w-[60%] max-lg:h-auto'>
                               <div className='m-3'>
                                   <img src={VectorThree} className='w-[100px] h-auto' alt=''></img>
                                </div>
                              <div className='m-2'>
                                <h2 className='text-[#ffff] text-[20px] font-bold'>Confirm Eligibility</h2>
                                <p className='text-[#ffff] text-[14px]'>Submit your KYC and Work Information, if approved you are eligible for monthly property rental on Rentease</p>
                                </div>
                            </div>
                            <div className='w-[248px] h-[100px] py-2 flex border-trasnparent bg-[#2B7CDC] rounded-lg px-3 max-lg:h-auto'>
                                <div className='m-3'>
                                    <img src={VectorF} className='w-[150px]  h-auto' alt=''></img>
                                </div>
                                <div className='m-2'>
                                    <h2 className='text-[#ffff] text-[20px] font-bold'>Book an appointment</h2>
                                    <p className='text-[#ffff] text-[14px]'>Schedule a meeting with an Agent, Inspect property and Request for property rental, pay 35% of the property rent excluding agency and caution fees and move in to the apartment, then pay the balance over a `10 month period.</p>
                                </div>
                            </div>
                        </div>
                        
                 </div>
            </div>
            <div className='w-full h-auto'>
                 <div className='bg-black bg-[url("components/img/lapi.png")] bg-no-repeat bg-cover relative w-full h-[320px] mt-[17%] rounded-lg'>
                 <a rel="noreferrer"  target='_blank' href='https://account.myrentease.co/'><button className='mt-5 absolute bg-[#ffff] border-2 border-transparent w-[152px] h-[50px] text-[#287BDC] text-[16px] rounded-md cursor-pointer right-8 bottom-8'>Get started</button></a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HowItWorks
