import React from "react";
// import { MdLocationOn } from 'react-icons/md';
// import { IoSearchCircle } from "react-icons/io5";
import sliderImage from "../img/sliderImage.png";
import arrow from "../img/arrow.svg";
import { HashLink as Link } from "react-router-hash-link";
const HeroPage = () => {
   // let iconStyle = {color:'#287BDC',marginLeft:'5px',marginTop:'3px', cursor:'pointer'}
   // let icon = {color:'#287BDC',marginTop:'6px',marginRight:'8px', cursor:'pointer'}
   return (
      <div>
         <div className=" px-14 w-full flex h-auto mt-[5%] max-lg:hidden">
            <div className="w-[50%] pt-[5%]">
               <h3 className=" text-[#287BDC] text-sm">You can rent properties with ease</h3>
               <h1 className="mt-3 text-[40px] text-[#000000] font-extrabold">
                  Choose your desired<br></br>property and pay installmentally<br></br>with{" "}
                  <span className="text-[#287BDC]">Rentease.</span>
               </h1>
               <p className="mt-3 text-sm text-[#6E6E74]">
                  Discover, Explore, Inspect<br></br>and Rent.
               </p>
               {/*<div className='mt-3 w-[60%] px-5 justify-evenly border-2 border-[#287BDC] rounded-md flex flex-row'><MdLocationOn size={30} style={icon}/>
                    <input type='text' placeholder='Enter a state, locality or area' 
                    className='w-full outline-none text-[13px] placeholder:text-[16px]'></input><IoSearchCircle size={38} style={iconStyle}/>
                </div>*/}
               <a target="_blank" href="https://explorer.myrentease.co/" rel="noreferrer">
                  <button className="mt-7 bg-[#287BDC] border-2 border-transparent w-[152px] h-[50px] text-[#FFFFFF] text-[16px] rounded-md cursor-pointer">
                     Explore
                  </button>
               </a>
               <div className="mt-7 flex justify-between w-[40%]">
                  <div>
                     <p className=" font-extrabold text-[#000000] text-[30px]">17M+</p>
                     <p className="text-[#6E6E74] text-[15px]">Properties</p>
                  </div>
                  <div>
                     <p className="font-extrabold text-[#000000] text-[30px]">20M+</p>
                     <p className="text-[#6E6E74] text-[15px]">users</p>
                  </div>
               </div>
            </div>
            <div className="w-[50%] ">
               <img src={sliderImage} className="float-right" alt=""></img>
            </div>
         </div>
         <div className="flex justify-center m-0 max-lg:hidden">
            <Link smooth to="#explore">
               <img alt="" src={arrow} className="cursor-pointer w-[160px]"></img>
            </Link>
         </div>
         {/*mobile page */}
         <div className=" w-full grid h-[80vh] mt-[5%] lg:hidden max-lg:mt-[2%]">
            <div className='w-full pt-[5%] bg-[url("components/img/small.png")] bg-no-repeat bg-cover max-lg:px-5 grid justify-center text-center'>
               <h3 className=" text-[#287BDC] text-[17px] max-md:text-[18px] max-lg:text-[22px]">
                  You can rent properties with ease
               </h3>
               <h1 className="mt-3 text-[22px] text-[#ffffff] font-extrabold max-md:text-[24px] max-lg:text-[30px] max-lg:mt-[4%]">
                  Choose your desired<br></br>property and pay installmentally<br></br>with{" "}
                  <span className="text-[#287BDC]">Rentease.</span>
               </h1>
               <p className="mt-2 text-[16px] text-[#ffffff] max-md:mt-[4%] max-md:text-[18px] max-lg:text-[22px] max-lg:mt-[4%]">
                  Discover, Explore, Inspect<br></br>and Rent.
               </p>
               {/*<div className='mt-3 w-full px-5 justify-center border-2 border-[#287BDC] bg-white rounded-md flex flex-row'>
                    <MdLocationOn size={30} style={icon}/>
                    <input type='text' placeholder='Enter a state, locality or area' 
                    className='w-full outline-none text-[13px] placeholder:text-[16px]'></input><IoSearchCircle size={38} style={iconStyle}/>
                </div>*/}
               <div className="grid lg:hidden justify-center max-md:mt-[7%] max-lg:mt-[7%]">
                  <a target="_blank" href="https://explorer.myrentease.co/" rel="noreferrer">
                     <button className=" bg-[#287BDC] border-2 border-transparent w-[152px] h-[50px] text-[#FFFFFF] text-[16px] rounded-md cursor-pointer max-lg:text-[20px] max-lg:w-[165px]">
                        Explore
                     </button>
                  </a>
               </div>
               <div className="flex justify-center mt-7 w-full px-12 max-lg:justify-between">
                  <div>
                     <p className=" font-extrabold text-white text-[20px]">17M+</p>
                     <p className="text-[#6E6E74] text-[15px]">Properties</p>
                  </div>
                  <div className=" m-0 lg:hidden w-[95px]">
                     <Link smooth to="#explore">
                        <img alt="" src={arrow} className="cursor-pointer w-[160px]"></img>
                     </Link>
                  </div>
                  <div>
                     <p className="font-extrabold text-white text-[20px]">20M+</p>
                     <p className="text-[#6E6E74] text-[15px]">users</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default HeroPage;
