import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/header/NavBar";
import LandingPage from "./components/landing/LandingPage";
import FaqPage from "./components/faqpage/FaqPage";
import FooterPage from "./components/footer/FooterPage";
import Terms from "./components/terms/Terms";
import Cookie from "./components/cookie/Cookie";
import Privacy from "./components/Privacy";
import Modal from "./components/modal/Modal";
import ChatWidget from "./liveChat";
import { useState } from "react";

function App() {
  const [show, setShow] = useState(true);

  return (
    <>
      <div style={{maxWidth: "1600px", margin: "auto"}}>
        <Router>
          {show && <Modal show={show} setShow={setShow} />}
          <NavBar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route index element={<LandingPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/Termsofservice" element={<Terms />} />
            <Route path="/Cookie" element={<Cookie />} />
            <Route path="/Privacy" element={<Privacy />} />
            <Route></Route>
            <Route></Route>
          </Routes>
          <FooterPage />
          <ChatWidget />
        </Router>
      </div>
    </>
  );
}

export default App;
