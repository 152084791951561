import React from 'react'

const Privacy = () => {
    return (
        <div className='grid gap-y-7 pt-5'>
            <head>
                <title>Privacy Statement</title>
            </head>
            <h1 className='flex justify-center font-bold text-[35px]'>PRIVACY STATEMENT</h1>
            {/* <h4 className='px-14 leading-relaxed opacity-50 flex flex-col'>
            </h4> */}
            <div className='px-14 grid gap-y-3'>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>What this Privacy Policy Covers:</h2>
                    <p className='opacity-50'>This Privacy Policy covers My Rent Lease Co. Limited’s treatment of personally identifiable
                        information that is submitted through My Rentease web site, and the use of My Rentease
                        on-line/website   services   and   contents   (which   on-line/website   services   and   contents   are
                        hereinafter jointly called "the Service"). This policy does not apply to the practices of other
                        companies or subsidiaries that are affiliated to My Rent Lease Co. Limited.</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Privacy Policy:</h2>
                    <h3 className=' font-bold text-[25px] opacity-100'>Information Collection & Use</h3>
                    <p className='opacity-50'>When you complete any form on My Rentease website, it is normal that we ask for such
                        information   as   your   name,   e-mail   address,   birth   date,   gender,   occupation,   industry,   and
                        personal interests. Once you fill the form on our website and submit the form, you are not
                        anonymous to us anymore.
                    </p>
                    <h3 className=' font-bold text-[25px] opacity-100'>Information Sharing & Disclosure</h3>
                    <p className='opacity-50'>My   Rentease   will   not   sell   or   rent   your   personally   identifiable   information   to   anyone.   My
                        Rentease   may   send   personally   identifiable   information   about   you   to   other   companies   or
                        people when:
                    </p>
                    <ul className=''>
                       <li><p className='opacity-50'>• We have your consent to share the information;</p></li>
                    <li><p className='opacity-50'>• The law requires it of us;</p></li>
                    <li><p className='opacity-50'>• We respond to subpoenas, court orders or legal process; or</p></li>
                    <li><p className='opacity-50'>• We   find   that   your   actions   on   our   web   sites   violate   any   part   of   the   My   Rentease   privacy
                        Policy.</p></li> 
                    </ul>
                    
                    <p className='opacity-50'>The   customers   shall   not   disclose   to   any   other   person,   in   any   manner   whatsoever,   any
                        information relating to My Rentease or its Affiliates of a confidential nature obtained in the
                        course of availing the services through the website. Failure to comply with this obligation
                        shall be deemed a serious breach of the terms herein and shall entitle My Rentease or its
                        Affiliates   to   terminate   the   services,   without   prejudice   to   any   damages,   to   which   the
                        company otherwise may be entitled.
                        My Rent ease will limit the collection and use of customer’s information only on a need-to-
                        know basis to deliver better service to the customer. My Rentease may use and share the
                        information   provided   by   its   customers   with   its   Affiliates   for   providing   services   and   any
                        service-related activities such as collecting subscription fees for such services, and notifying
                        or contacting the customers regarding any problem with, or the expiration of such services.
                        In this regard, it may be necessary to disclose the customer's information to My Rentease
                        affiliate who will be required to agree to use the information obtained from My Rentease
                        only for these purposes.</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Changes to this Privacy Policy</h2>
                    <p className='opacity-50'>My Rentease may amend this policy from time to time. If we make any substantial changes
                        in   the   way   we   use   your   personal   information   we   will   notify   you   by   posting   a   prominent
                        notice on our website.</p>

                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Questions or Suggestions</h2>
                    <p className='opacity-50'>If you have questions or suggestions complete the Contact Form.</p>
                    <h3>Terms Of Use</h3>
                    <p className='opacity-50'>You   understand   and   agree   that   the   contents   of   this   website   include   all   audio,   video,
                        graphics,   images   and   textual   materials,   downloadable   files,   pdfs,   Microsoft   word,   etc.   All
                        contents are protected by International copyright law. None of the contents on this website
                        shall be used for any commercial purposes without the written permission of My Rentease.
                        You acknowledge and agree that the Service and any necessary software used in connection
                        with   the   Service   contain   proprietary   and   confidential   information   that   is   protected   by
                        applicable intellectual property and other laws. You further acknowledge and agree that the
                        contents of sponsored advertisements or information presented to you through the Service
                        or   advertisers   is   protected   by   copyrights,   trademarks,   service   marks,   patents   or   other
                        proprietary rights and laws. Except as expressly authorized by My Rentease, you agree not
                        to modify, rent, lease, loan, sell, distribute or create derivative works based on the Service
                        or the Software, in whole or in part. My Rentease grants you a personal, non-transferable
                        and non-exclusive right and license to use the contents of the site; provided that you do so
                        within the ambit of the Terms Of Service.
                        In addition, when using a particular My Rentease services, you and My Rentease shall be
                        subject to any posted guidelines or rules applicable to such services, which may be posted
                        from time to time. All such guidelines or rules are hereby incorporated by reference into the
                        Terms Of Service.</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Description of Service</h2>
                    <p className='opacity-50'>My   Rentease   website   provides   you   with   access   to   resources   that   are   rich   in   content
                        concerning   the   Company   and   its   services.   You   understand   and   agree   that   the   Service   is
                        provided   "AS-IS"   and   that   My   Rentease   assumes   no   responsibility   for   the   timeliness,
                        deletion,   or   failure   to   store   any   user   communications.   You   are   responsible   for   obtaining
                        access to the Service and that access may involve third party fees (such as Internet service
                        provider or airtime charges). You are responsible for those fees.</p>

                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Requirements for Filling Online Form</h2>
                    <p className='opacity-50'>In consideration of your use of the Service, you agree to: provide true, accurate, current and
                        complete information about yourself as indicated in the form's section. If you provide any
                        information that is untrue, inaccurate, not current or incomplete, and My Rentease has a
                        reasonable ground to suspect that such information is untrue, inaccurate, not current, or
                        incomplete, My Rentease has the right to delete your form data from our server.</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Web Account Opening</h2>
                    <p className='opacity-50'>Your initiation of the web account opening process does not automatically guarantee that
                        the account(s) will be opened on your behalf. You agree that the account opening will be
                        subject to the company’s processes and reviews which may require you to provide further
                        confirmation   or   documents.   You   agree   to   comply   with   the   standard   account   opening
                        documentation requirements and to meet KYC requirements as may be stipulated by My
                        Rentease. My Rentease reserves the right to accept or reject your application.  We refer you
                        the Terms of Service and Conditions.</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Indemnity</h2>
                    <p className='opacity-50'>You   agree   to   indemnify   and   hold   My   Rentease,   and   its   subsidiaries,   affiliates,   officers,
                        agents, co-branders or other partners, and employees, harmless from any claim or demand,
                        including reasonable attorneys' fees, made by any third party due to or arising out of any
                        matter you submit, post, transmit or make available through the Service, your use of the
                        Service,   your   connection   to   the   Service,   your   violation   of   the   Terms   Of   Service,   or   your
                        violation of any rights of another.</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Limitation of Liability</h2>
                    <p className='opacity-50'>You expressly understand and agree that My Rentease   shall not be liable for any direct,
                        indirect, incidental, special, consequential or exemplary damages, including but not limited
                        to, damages for loss of profits, goodwill, use, data or other intangible losses (even if My
                        Rentease has been advised of the possibility of such damages) resulting from:</p>
                    <ul className='flex flex-col gap-y-2 opacity-50'>
                        <li><h4>• The use or the inability to use the service;</h4></li>
                        <li><h4>• The cost of procurement of substitute goods and services resulting from any goods, data,
                            information or services purchased or obtained or messages received or transactions entered
                            into through or from the service;</h4></li>
                        <li><h4>• Unauthorized access to or alteration of your transmissions or data;</h4></li>
                        <li><h4>• Statements or conduct of any third party on the service; or</h4></li>
                        <li><h4>• Safari (Mobile)</h4></li>
                        <li><h4>• Any other matter relating to the service.</h4></li>
                        {/* <li><h4>• Opera</h4></li>
                        <li><h4>• Opera Mobile</h4></li> */}
                    </ul>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Copyrights</h2>
                    <p className='opacity-50'>My Rentease respects the intellectual property of others, and we ask our customers to do
                        the same. If you believe that your work has been copied in a way that constitutes copyright
                        infringement,   or   your   intellectual   property   rights   have   been   otherwise   violated,   please
                        provide My Rentease's Webmaster the following information:</p>
                    <ul className='flex flex-col gap-y-2 opacity-50'>
                        <li><h4>• An electronic or physical signature of the person authorized to act on behalf of the owner of
                            the copyright or other intellectual property interest;</h4></li>
                        <li><h4>• TA description of the copyrighted work or other intellectual property that you claim has been
                            infringed</h4></li>
                        <li><h4>• A description of where the material that you claim is infringing is located on the site;</h4></li>
                        <li><h4>• Your address, telephone number, and e-mail address;</h4></li>
                        <li><h4>• A statement by you that you have a good faith belief that the disputed use is not authorized
                            by the copyright owner, its agent, or the law;</h4></li>
                        <li><h4>• A   statement   by   you,   made   under   penalty   of   perjury,   that   the   above   information   in   your
                            Notice   is   accurate   and   that   you   are   the   copyright   or   intellectual   property   owner   or
                            authorized to act on the copyright or intellectual property owner's behalf.</h4></li>
                        {/* <li><h4>• Opera</h4></li>
                        <li><h4>• Opera Mobile</h4></li> */}
                    </ul>
                    <p className='opacity-50'>My   Rentease's   Agent   for   Notice   of   claims   of   copyright   or   other   intellectual   property
                        infringement can be reached through webmaster@myrentease.co</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>
                        General Information</h2>
                    <p className='opacity-50'>The Terms of Service constitute the entire agreement between you and My Rentease and
                        govern   your   use   of   the   Service,   superseding   any   prior   agreements   between   you   and   My
                        Rentease. You also may be subject to additional terms and conditions that may apply when
                        you use affiliate services, third-party matter or third-party software. The Terms of Service
                        and the relationship between you and My Rentease shall be governed by the laws of the
                        Federal   Republic   of   Nigeria   without   regard   to   its   conflict   of   law   provisions.   You   and   My
                        Rentease agree to submit to the personal and exclusive jurisdiction of the courts located
                        within Lagos State of Nigeria. The failure of My Rentease to exercise or enforce any right or
                        provision of the Terms of Service shall not constitute a waiver of such right or provision or
                        prevent a subsequent enforcement of that or any other right or provision. If any provision of
                        the Terms of Service is found by a court of competent jurisdiction to be invalid, the parties
                        nevertheless agree that the court should endeavour to give effect to the parties' intentions
                        as reflected in the provision, and that all the provisions of the Terms Of Service shall remain
                        in full force and effect. You agree that regardless of any provision of any statute or law to
                        the contrary, any claim or cause of action arising out of or related to use of the Service or
                        the Terms of Service must be filed within one (1) year after such claim or cause of action
                        arose or be forever barred and extinguished.</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Trademark Information</h2>
                    <p className='opacity-50'>The name " My Rentease " logo is a  trademark of My Rent Lease Co. Limited.</p>
                </span>
            </div>
        </div>
    )
}

export default Privacy