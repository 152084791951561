import React from 'react';
import { Helmet } from 'react-helmet';

const ChatWidget = () => {
  return (
    <div >
      {/* Embed Tawk.to widget code */}
      <Helmet>
        <script type="text/javascript">
          {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/64f717c2a91e863a5c11c032/1h9ij9k2l';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })();
          `}
        </script>
      </Helmet>
      {/* Your other React components */}
    </div>
  );
};

export default ChatWidget;
