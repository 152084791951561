import React from 'react'

const Terms = () => {
    return (
        <div className='grid gap-y-7 pt-5'>
            <head>
                <title>Terms of Service</title>
            </head>
            <h1 className='flex justify-center font-bold text-[35px] items-center'><h3 className='text-[52px] items-start'>A</h3>GREEMENT TO OUR LEGAL TERMS OF SERVICE</h1>
            <h3 className='italic flex justify-center opacity-50'>last updated january 2023</h3>
            <h4 className='px-14 leading-relaxed opacity-50 flex flex-col'>
                <span> These Legal terms constitute a legal binding agreement made between you, whether
                personally or on behalf you and MY RENTEASE, concerning your access to and use of the
                services. You agree that by accessing the services, you have read, understood, and agreed
                to be bound by all of these legal term. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL
                TERMS, THEN YOU ARE EXPRESSLY PROHIBITEDFROM USING THE SERVICES AND YOU MUST
                DISCONTINUE IMMEDIATELY.</span>
                <span>
                Supplemental   terms   and   conditions   or   documents   that   may   be   posted   on   the
                services/website from time to time are hereby expressly incorporated herein by reference.
                We reserve the right, in our sole discretion, to make changes or modifications to these legal
                terms at any time and for any reason.  We will alert you about any changes by updating the
                “Last Updated” date of theses legal terms and you waive any right to receive specific notice
                of each such change. It is your responsibility to periodically review these legal terms to stay
                informed of updates. You will be subject to and be deemed to have been made aware of and
                to have accepted the changes in any revised Legal terms by your continued use of the
                services and after the date such revised legal terms are posted.
                We recommend that you print a copy of these legal terms for your records.</span>
            </h4>
            <div className='px-14 grid gap-y-3 opacity-50'>
                <ul className='grid gap-y-2'>
                    <li><h3>TABLE OF CONTENTS</h3></li>
                    <li><h4>• OUR SERVICES</h4></li>
                    <li><h4>• INTELLECTUAL PROPERTY RIGHTS</h4></li>
                    <li><h4>• USER REPRESENTATION</h4></li>
                    <li><h4>• USER REGISTRATION</h4></li>
                    <li><h4>• REPAYMENT</h4></li>
                    <li><h4>• POLICY</h4></li>
                    <li><h4>• PROHIBITED ACTIVITIES</h4></li>
                    <li><h4>• USER GENERATED CONTRIBUTIONS</h4></li>
                    <li><h4>• CONTRIBUTION LICENCE</h4></li>
                    <li><h4>• GUIDELINES FOR REVIEWS</h4></li>
                    <li><h4>• SOCIAL MEDIA</h4></li>
                    <li><h4>• THIRD PARTY WEBSITES AND CONTENT</h4></li>
                    <li><h4>• ADVERTISERS</h4></li>
                    <li><h4>• SERVICES MANAGEMENT</h4></li>
                    <li><h4>• PRIVACY POLICY</h4></li>
                    <li><h4>• COPYRIGHT INFRIGEMENT</h4></li>
                    <li><h4>• TERM AND TERMINATION</h4></li>
                    <li><h4>• MODIFICATIONS AND INTERRUPTION</h4></li>
                    <li><h4>• GOVERNING LAW</h4></li>
                    <li><h4>• DISPUTE RESOLUTION</h4></li>
                    <li><h4>• CORRECTIONS</h4></li>
                    <li><h4>• DISCLAIMER</h4></li>
                    <li><h4>• LIMITATION OF LIABILITIES</h4></li>
                    <li><h4>• INDEMNIFICATION</h4></li>
                    <li><h4>• USER DATA</h4></li>
                    <li><h4>• ELECTRONIC, COMMUNICATION, TRANSACTIONS, AND SIGNATURES</h4></li>
                    <li><h4>• MISCELLANEOUS</h4></li>
                    <li><h4>• CONTACT US</h4></li>
                </ul>
                <span className='flex flex-col gap-y-1'>
                    <h2>• OUR SERVICES </h2>
                    The information provided when using the services is not intended for distribution to
                    or use by any person or entity in any jurisdiction or country where such distribution
                    or use would be contrary to law or regulation or which would subject us to any
                    registration   requirement   within   such   jurisdiction   or   country.   Accordingly,   those
                    persons who choose to access the services from other locations do so on their own
                    initiative and are solely responsible for compliance with local laws, if and to the
                    extent local laws are applicable.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• INTELLECTUAL PROPERTY RIGHTS </h2>
                    Our Intellectual Property
                    • We are the owner or the licensee of all intellectual property rights in our services,
                    including all source code, database, functionality, software, website, designs,
                    audio, video, text, photographs,, and graphics in the services (collectively the
                    “the Content”, as well as as the trademarks, service marks, logos, contained
                    therein (the Marks). Our content and marks are protected by copyright and
                    trademark laws (and various intellectual property rights and unfair competition
                    laws) and treaties in other parts of the world.
                    • The Content and Marks are provided in or through the services “AS IS” for your
                    personal, non-commercial use or internal business purpose only.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>Your Use Of Our Services </h2>
                    • Subject to your compliance with these legal terms, including the “the Prohibited
                    Activities”   section   below,   we   grant   you   a   non-exclusive,   non-transferable,
                    revocable license to
                    • Access the Services, and
                    • Download or print a copy of any portion of the Content to which you have
                    properly gained access
                    • Solely for your personal, non- commercial and use or internal business purpose.
                    • Except as set out in this section or elsewhere in our legal terms, no part of the
                    services   and   no   content   or   Marks   may   be   copied,   reproduced,   aggregated,
                    republished,   uploaded,   posted,   publicly   displayed,   encoded,   translated,
                    transmitted, distributed, sold, licensed, or otherwise exploited for any commercial
                    purpose  whatsoever, without our express prior written permission.
                    • If you wish to make any use of the services, Content, or Marks other than as set
                    out in this section or elsewhere in our legal terms , please address your request
                    to my @webstarmyrentease.co. if we ever grant you the permission to post,
                    reproduce, or publicly display any part of our services or content, you must
                    identify us as the owners or licensors of the services, Content, or Marks and
                    ensure that any copyright or proprietary notice appears or is visible on posting,
                    reproducing, or displaying our content.
                    • We reserve all rights not expressly granted to you in and to the services, content,
                    and Marks.
                    Any breach of these Intellectual Property Rights will constitute a material breach of our
                    legal Terms and your right to use our services will terminate immediately.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>Your Submissions </h2>
                    • Please review this section and the “PROHIBITED ACTIVITIES”SECTION CAREFULLY
                    prior to using our services to understand the (a) rights you give us and (b)
                    obligations you have when you post or upload any content through the services.
                    • Submissions: by directly sending us any question, comment, suggestion, idea,
                    feedback. Or other information about the services, you agree to assign us all
                    intellectual property rights in such submission. You agree that we shall own this
                    submission and be entitled to its unrestricted use and dissemination for any
                    unlawful   purpose,   commercial   or   otherwise,   without   acknowledgement   and
                    compensation.
                    • You are responsible for what you post or upload: By sending us submissions
                    through any part of the services you
                    • Confirm that you have read and agree with our “PROHIBITED ACTIVITIES “and will
                    not post, publish, upload, or transmit through the services any submission that is
                    illegal, harassing, hateful, harmful, defamatory, obscene,, bullying, aggressive,
                    discriminatory,   threatening,   to   any   person   or   group,   sexually   explicit,   false,
                    inaccurate, deceitful or misleading.
                    • To the extent permissible by applicable law, waive any and all moral rights  to
                    any submission
                    • Warrant that any such submission are original to you or that you have the
                    necessary rights and licenses to submit such submissions and that you have full
                    authority to grant us the above-mentioned rights in relation to your submissions
                    and;
                    • Warrant   and   represent   that   your   submissions   do   not   constitute   confidential
                    information.
                    • You are solely responsible for your submissions and you expressly agree to
                    reimburse us for any and all losses that we may suffer because of your breach (a)
                    this section (b) any third’s party ‘s intellectual property rights, or (c) applicable
                    law.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• USER REPRESENTATION </h2>
                    By using the service, you represent and warrant that :
                    • registration information you submit will be true, accurate, current, and
                    complete;
                    •  you will maintain the accuracy of such information and promptly update
                    such registration information as necessary;
                    •   you have the legal capacity and you agree to comply with these legal
                    terms;
                    •  you are not a minor in the jurisdiction you reside;
                    • you will not access the services through automated or non-human means,
                    whether through a bot, script, or otherwise;
                    • you will not use the services for any illegal or unauthorized purpose; and
                    • your use of the services will not violate any applicable law or regulation.
                    • If you provide any information that is untrue, inaccurate, not current, or incomplete, we
                    have the right to suspend or terminate your account and refuse any and all current or
                    future use of the Services (or any portion thereof).
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• USER REGISTRATION </h2>
                    You may be required to register to use the Services. You agree to keep your password
                    confidential and will eb responsible for all use of your account and password. We reserve
                    the right to remove, reclaim, or change a username you select if we determine, in our
                    sole   discretion,   that   such   username   is   inappropriate,   obscene,   or   otherwise
                    objectionable.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• RE-PAYMENT </h2>
                    • We accept the following forms of payment: Mastercard, Visa, verve and  Remita.
                    • You will agree to provide current and accurate purchase and account information for all
                    purchases made via services. You further agree o promptly update account and payment
                    information, including email addresses, payment method, and payment card expiration,
                    so that we can complete you transactions and contact you as needed. Value Tax will be
                    added to the services as deemed required by us. All payments shall be in Naira.
                    • You agree to pay all charges, contributions, insurance, and caution fee upon
                    your acceptance of the rental Loan. We reserve the right to correct any errors
                    or mistakes in re-payment, even if we have already requested or received
                    payment.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• POLICY </h2>
                    This service is inclusive of a Credit Policy.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>PROHIBITED ACTIVITIES </h2>
                    •  You may not access or use the Services for any purpose other than that for which we
                    make the Services available. The Services may not be used in connection with any
                    commercial endeavors except those that are specifically endorsed or approved by us.
                    • As a user of the services, you agree not to:
                    • Systematically retrieve data or other content from the Services to create or compile,
                    directly or indirectly, a collection, compilation, database, or directory without written
                    permission from us.
                    • Trick, defraud, or mislead us and other users, especially in any attempt to learn
                    sensitive account information such as user passwords.
                    •   Circumvent, disable, or otherwise interfere with security-related features of the
                    services, including features that prevent or restrict the use or copying of any Content
                    or enforce limitations on the use of the Services and/or the Content contained
                    therein.
                    • Disparage, tarnish or otherwise harm, in our opinion, us, and/or the services.
                    • Use any information obtained from the services in order to harass, abuse, or harm
                    another person.
                    • Make improper use of our support services or submit false reports of abuse or
                    misconduct.
                    • Use the services in a manner inconsistent with any applicable laws or regulations.
                    • Engage in unauthorized framing of or linking to the service.
                    • Upload or transmit (or attempt to upload or to transmit viruses, Trojan horses, or
                    other material, including excessive use of capital letters and spamming (continuous
                    posting of repetitive text), that interferes with any party’s uninterrupted use and
                    enjoyment of the services or modifies, impairs, disrupts, alters, or interferes with the
                    use, features, functions, operations, or maintenance of the services.
                    • Engage in any automated use of the system, such as using scripts to send comments
                    or  messages,  or  using  any  data  mining,  robots,  or  similar  data  gathering  and
                    extraction tools.
                    • Delete the copyright or other proprietary rights notice from any Content.
                    • Attempt to impersonate another user or person or use the username of another user.
                    • Upload or transmit (or attempt to upload or to transmit) any material that acts as a
                    passive or active information collection or transmission mechanism, including without
                    limitation, clear graphics interchange formats (gifts), 1x1 pixels, web bugs, cookies,
                    or other similar devices (sometimes referred to as spyware or passive collection
                    mechanisms or agents)
                    • Interfere with or disrupt or create an undue burden on the services or the networks or
                    services connected to the services.
                    • Harass, annoy, intimidate, or threaten any of our employees or agents engaged in
                    providing any portion of services to you.
                    • Attempt to bypass any measures of the services designed to prevent or restrict
                    access to the services, or any portion of the services.
                    • Copy or adapt the services software, including but not limited to Flash, PHP, HTML,
                    JavaScript or other code.
                    • Except as permitted by applicable law, decipher, decompile, disassemble, or reverse
                    engineer any of the software comprising or in any way making up a part of the
                    services.
                    • Except as may be the result of standard search engine or internet browser usage,
                    use,   launch,   develop,   or   distribute   any   automated   system,   including   without
                    limitation, any spider, robot, cheat, utility, scraper, or offline reader that accesses the
                    services, or use or launch any unauthorized script or other software.
                    • Use a buying agent or purchasing agent to make purchases on the services.
                    • Make any unauthorized use of the services, including collecting usernames, and/or
                    email addresses of users by electronic or other means for the purpose of sending
                    unsolicited email, or creating user accounts by automated means or under false
                    pretenses.
                    • Use the services as part of any effort to compete with us or otherwise use the
                    services and/or the content for any revenue-generating endeavor or commercial
                    enterprise.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• USER GENERATED CONTRIBUTIONS </h2>
                    • The services does not offer users to submit or post content. We may provide you with
                    the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or
                    broadcast content and materials to us or on the services, including but not limited to
                    text, writings, video, audio, photographs, graphics, comments, suggestions, or personal
                    information   or   other   material   (   collectively,   Contributions).   Contributions   may   be
                    viewable by other users of the services and through third-party websites. When you
                    create or make available any contributions, you thereby represent and warrant that :


















                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• CONTRIBUTION LICENCE </h2>
                    • You   and   MYRENTEASE   agree   that   we   may   access,   store,   process,   and   use   any
                    information   and   personal   data   that   you   may   provide   and   your   choices   (including
                    settings).
                    • By submitting suggestions or other feedback regarding MY RENTEASE, you agree that we
                    can use and share such feedback for any purpose without compensation to you.
                    • We do not assert any ownership over your contributions. You retain full ownership of all
                    of your contributions and any intellectual property rights or other proprietary rights
                    associated   with   your   contributions.   We   are   not   liable   for   any   statements   or
                    representations in your contributions provided by you in any area on the services. You
                    are   solely   responsible   for   your   contribution/statements/information   given   to   MY
                    RENTEASE  and you agree to exonerate us from any and all responsibility and to refrain
                    from any legal action against us regarding your contribution.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• GUIDELINES FOR REVIEWS </h2>
                    • We may provide you areas on the services to leave reviews or ratings. When posting a
                    review, you must comply with the following criteria:
                    • You should have firsthand experience with the person/entity being reviewed;
                    • Your reviews should not contain offensive, profanity, or abusive, racist, offensive, or
                    hateful language;
                    • Your reviews should not contain discriminatory references based on religion, race,
                    gender, national, origin, age, marital status, sexual orientation, or disability;
                    • Your reviews should not contain references to illegal activity;
                    • You should not be affiliated with competitors if posting negative reviews;
                    • You should not make any conclusions as to the legality of conduct;
                    • You may not post any false or misleading statements; and
                    • You may not organize a campaign encouraging others to posts reviews, whether
                    positive or negative.
                    • We may accept, reject or remove in our sole discretion. We have absolutely no obligation
                    to screen reviews or to delete reviews, even if anyone considers reviews objectionable or
                    inaccurate. Reviews are not endorsed by us, and do not necessarily represent our
                    opinions or the views of any of our affiliates or partners. We do not assume liability for
                    any review or for any claims, liabilities, or losses resulting from any review. By posting a
                    review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free,
                    assignable, and sublicensable right and license to reproduce, modify, translate, transmit
                    by any means, display, perform, and/or distribute all content relating to review.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• SOCIAL MEDIA</h2>
                    • As part of the functionality of the Services, you may link your account with online
                    accounts you have with third-party Service providers ( each such account, a ‘third Party
                    Account’)  by either:
                    • Providing your Third-Party Account Login Information through the services; or
                    • Allowing us access to third-party account, as is permitted under the applicable terms
                    and conditions that govern your use of each Third-Party Account. You represent and
                    warrant that you entitled to disclose your third-party account login information to us
                    and/or grant us access to your Third-Party Account, without breach by you of any of
                    the terms and conditions that govern your use of the applicable Third-Party Account
                    and without obligating us to pay any fees or making us subject to any usage
                    limitations imposed by the third-party service provider of the third-Party Account. By
                    granting us access to any Third-Party Accounts, you understand that;
                    • We may access, make available, and store (if applicable) any content that you have
                    provided to and stored in your Third-Party Accounts, ( the social Network Content) so
                    that it is available on and through the services via your account, including without
                    limitation any friend lists and
                    ii.    we may submit to and receive from your Third-Party Account additional information
                    to the extent you are notified when you link your account with the third-party account.
                    Depending on the third-party accounts you choose and subject to the privacy settings
                    that you have set in such third-party accounts, personally identifiable information that
                    you post to your third-party account may be available on and through your account on
                    the services. Please note that if a third-part account or associated service becomes
                    unavailable or our access to such third-party account is terminated by the third-party
                    provider, then social network content may no longer be available on and through the
                    services.  You have the ability to disable the connection between your account on the
                    services and your third-party service provider at any time. PLEASE NOTE THAT YOUR
                    RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR
                    THIRD-PARTY ACCOUNT IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
                    THIRD-PARTY SERVICE PROVIDERS.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• THIRD-PARTY WEBSITES AND CONTENT</h2>
                    The Services may contain (or you may be sent via the site) links to other websites (‘Third-
                    Party Websites’) as well as articles, photographs, text, graphics, pictures, designs, music,
                    sound, video, information, applications, software, and other content or items belonging to or
                    originating from third parties (‘Third-Party Content’). Such Third-Party Websites and Third-
                    Party Content  are not investigated, monitored, or checked for accuracy, appropriateness, or
                    completeness by us, and we are not responsible for any Third-Party Websites accessed
                    through the Services or any Third-Party Content posted on, available through, or installed
                    from   the   services,   including   the   content,   accuracy,   offensiveness,   opinions,   reliability,
                    privacy practices, or other policies of or contained in the Third-Party Websites or the Third-
                    Party Content. Inclusion of or permitting the use or installation of any Third-Party Websites
                    or any Third-Party Content does not imply approval or endorsement thereof by us. If you
                    decide to leave the services and access the Third-Party Websites or to use or install any
                    Third-Party Content, you do so at your own risk, and you should be aware these Legal Terms
                    of Service no longer govern. You should review the applicable terms and policies, including
                    privacy and data gathering practices, of any website to which you navigate from the
                    services or relating to any applications you use or install from the services. Any purchases
                    you make through Third-Party Website will be through other websites and from other
                    companies, and we take no responsibility whatsoever in relation to such purchases which
                    are exclusively between you and the applicable third party. You agree and acknowledge that
                    we do not endorse the products or services offered on Third-Party Websites and you shall
                    hold us blameless from any harm caused by purchase of such products or services.
                    Additionally, you shall hold us blameless from any losses sustained by you or harm caused
                    to you relating or resulting in any way from any Third-Party Content or any contact with
                    Third-Party Websites.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• ADVERTISERS</h2>
                    We allow advertisers to display their advertisement and other information in certain
                    areas of the services, such as sidebar advertisements or banner advertisements. We
                    simply   provide   the   space   to   place   such   advertisements,   and   we   have   no   other
                    relationship with advertisers.
                    • SERVICES MANAGEMENT
                    We reserve the right, but not the obligation, to:
                    (1) monitor the services for violations of these Legal Terms;
                    (2) take appropriate legal action against anyone who, in our sole discretion, violates the
                    law   or   these   legal   terms,   including   without   limitation,   reporting   such   user   to   law
                    enforcement authorities;
                    (3) in our sole discretion and without limitation, refuse, restrict access to, limit the
                    availability of, or disable (to the extent technologically feasible) any of your contributions
                    or any portion thereof;
                    (4) in our sole discretion and without limitation, notice, or liability, to remove from the
                    services or otherwise disable all files and content that are excessive in size or are in any
                    way burdensome to our systems; and
                    (5) otherwise manage the services in a manner designed to protect our rights and
                    property and to facilitate the proper functioning of the services.
                    • PRIVACY POLICY
                    we care about data privacy and security. By using the Services, you agree to be bound
                    by our privacy policy posted on the services, which is incorporated into these Legal
                    Terms of service. Please be advised the service are hosted in Nigeria. If you access the
                    services from any other region of the world with laws or other requirements governing
                    personal data collection, use, or disclosure that differ from applicable laws in Nigeria,
                    then through your continued use of the services, you are transferring your data to
                    Nigeria, and you expressly consent to have your data transferred to and processed in
                    Nigeria.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• COPYRIGHT INFRINGEMENT</h2>
                    We respect the intellectual property rights of others. If you believe that any material
                    available on or through the services infringe upon any copyright you own or control,
                    please   immediately   notify   us   using   the   contact   information   provided   below   (a
                    Notification). A copy of your notification will be sent to the person who posted or stored
                    the material addressed in the Notification. Please be advised that pursuant Applicable
                    law you may be held liable for damages. If you make material misrepresentation in a
                    Notification. Thus, if you are not sure that material Located on or linked to by the
                    services infringes your copyright, you should consider first contacting an attorney.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• TERMS AND TERMINATION</h2>
                    •  These Legal terms shall remain in full force and effect while you use the services of MY
                    RENTEASE. WITHOUT LIMITATING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE
                    RESERVE THE RIGT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
                    DENY   ACCESS   TO   AND   USE   OF   THE   SERVICES   (INCLUDING   BLOCKING   CERTAIN   IP
                    ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING
                    WITHOUT   LIMITATION   FOR   BREACH   OF   ANY   REPRESENTATION,   WARRANTY,   OR
                    CONVENANT   CONTAINED   IN   THESE   LEGAL   TERMS   OR   ANY   APPLICABLE   LAW   OR
                    REGULATION, WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR
                    DELETE   CONTENT   OR   INFORMATION   THAT   YOU   POSTED   AT   ANY   TIME,   WITHOUT
                    WARNING, IN OUR SOLE DISCRETION.
                    • If we terminate or suspend your account for any reason, you are prohibited from
                    registering and creating a new account under your name, a fake or borrowed name, or in
                    the name of any third party. In addition to terminating or suspending your account, we
                    reserve the right the right to take appropriate legal action, including without limitation
                    pursuing civil, criminal, and injunctive redress.
                    • MODIFICATIONS AND INTERRUPTIONS
                    • We reserve the right to change, modify or remove the contents of the services at any
                    time or for any reason at our sole discretion without notice. However we have no
                    obligation to update any information on our services. We will not be liable to you or any
                    third party for any modification, price change, suspension, or discontinuance of the
                    Services.
                    • We cannot guarantee the services will be available at all times. We may experience
                    hardware, software, or other problems, or need to perform maintenance related to MY
                    RENTEASE Services, resulting in interruptions, delays, or errors. We reserve the right to
                    change, reserve, update, suspend, discontinue, or otherwise modify the services at any
                    tie  or  for  any  reason  without  notice  to  you.  You  agree  that  we  have  no  liability
                    whatsoever for any loss, damage, or inconvenience caused by inability to access or use
                    the services during downtime or discontinuance of the services. Nothing in these Legal
                    terms will be construed to obligate us to maintain and support the services or to supply
                    any corrections, updates, or releases in connection therewith.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• GOVERNING LAW</h2>
                    • These Legal Terms of Service shall be governed by and defined by the Laws of the
                    Federal Republic Of Nigeria and MY RENTEASE and yourself irrevocably consent that the
                    Courts of the Federal Republic of Nigeria shall have exclusive Jurisdiction to resolve any
                    disrepute which may arise in connection with these Legal Terms of Service.
                    • DISPUTE RESOLUTION
                    • Informal Negotiations
                    To expedite resolution and control the cost of any dispute, controversy, or claim related
                    to these Legal Terms of Service (each a ‘Dispute’ and collectively, the ‘Disputes’)
                    brought by either you or us (individually, a ‘Party’ and collectively ‘Parties’), the Parties
                    agree   to   first   attempt   to   negotiate   any   Dispute   (except   those   Disputes   expressly
                    provided below) informally for at least (30) days before initiating arbitration. Such
                    informal negotiations commence upon written notice from one Party to the other Party.
                    • Binding Arbitration
                    Any dispute arising out of or in connection with these Legal Terms, including any
                    question regarding its existence, validity, or termination, shall be referred to and finally
                    resolved   by   the   International   Commercial   Arbitration   Court   under   the   European
                    Arbitration Chamber (Belgium, Brussels, Avenue Louise, 148) according to  the rules of
                    this CAC, which, as a result of referring to it, is considered as the part of this clause. The
                    number of arbitrators shall be (1) one. The seat, or legal place, or arbitration shall be
                    Lagos, Nigeria. The language of the proceedings shall be English. The governing law of
                    these Legal Terms of Service shall be the substantive Law of Nigeria.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• Restrictions</h2>
                    The Parties agree that any arbitration shall be limited to the Dispute between the Parties
                    individually. To the full extent permitted by law,(a) no arbitration joined with any other
                    proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-
                    action basis or to utilize class action procedures; (c) there is no right or authority for any
                    Dispute to be brought in a purported representative capacity on behalf of the general
                    public or any other persons.
                    • Exceptions To Informal Negotiations and Arbitration
                    The parties agree that following Disputes are not subject to the above provisions
                    concerning informal negotiations binding arbitration: (a) any Disputes seeking to enforce
                    or protect, or concerning the validity of, any of the intellectual property rights of a Party;
                    (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of
                    privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is
                    found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute
                    falling within that portion of this provision found to be illegal or unenforceable and such
                    Dispute shall be decided by a court of competent jurisdiction within the courts listed for
                    jurisdiction above, and the parties agree to submit to the personal jurisdiction of that
                    court.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• CORRECTIONS</h2>
                    There   may   be   information   on   the   services   that   contains   typographical   errors,
                    inaccuracies, or omissions, including descriptions, pricing, availability, and various other
                    information. We reserve the right to correct any errors, inaccuracies, or omissions and to
                    change or update the information on the Services at any time, without prior notice.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• DISCLAIMER</h2>
                    THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
                    YOUR USE OF THE SERVICES WILL E AT YOUR SOLE RISK. TO THE FULLEST EXTENT
                    PERMITTED   BY   LAW,   WE   DISCLAIM   ALL   WARRANTIES   EXPRESS   OR   IMPLIED,   IN
                    CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING WITHOUT
                    LIMITATION,   THE   IMPLIED   WARRANTIESOF   MERCHANTABILITY,   FITNESS   FOR   A
                    PARTICULAR   PURPOSE,   AND   NON-INFRIGEMENT.   WE   MAKE   NO   WARRANTIES   OR
                    REPRESENTATIONS   ABOUT   THE   ACCURACY   OR   COMPLETENESS   OF   THE   SERVICES
                    CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO
                    THE SERVICES AND WE SHALL ASSUMME NO LIABILITY OR RESPONSIBILITY FOR ANY (I)
                    ERRORS, MISTAKES, OR INACCURACES OF CONTENT AND MATERIALS, (II)  PERSSONAL
                    INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                    WHATSOEVER, RESULTING FROM YOUR ACCESS AND USE OF THE SERVICES, (III)ANY
                    UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS  AND/OR ANY AND ALL
                    PERSONAL INFORMATION AND/OR FINSNCILA INFORMATION STORED THEREIN, (IV) ANY
                    INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM ANY SERVICES, (V) ANY
                    BUGS, TROJAN HORSS OR THE LINK WHICH MAY BE TRANSMITTED TO OR THROUGH THE
                    SERVICES   B   ANY   THIRD   PARTY,   AND/OR   (VI)   ANY   ERRORS   OR   OMISSIONS   IN   ANY
                    CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
                    RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTERWISE MADE
                    AVAILABLE   VIA   THE   SERVICES.   WE   DON’T   WARRANT,   ENDORSE,   GUARANTEE,   OR
                    ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SREVVICE ADVERTISED OR OFFERED BY
                    A THIRD PARTY THROUGH THE SERVICS, AND HYPERLINKED WEBSITE OR ANY WEBSITE
                    OR MOBILE APPLICATION FEATURED IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
                    TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OR PRODUCTS OR
                    SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM
                    OR IN ANY ENVIROMENT, YOU SHOULD USE YOUR BEST JUDGEMNT AND EXERCISE
                    CAUTION WHERE APPROPRIATE.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• LIMITATIONS OF LIABILITY</h2>
                    IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU
                    OR   ANY   THIRD   PARTY   FOR   ANY   DIRECT,   INDIRECT   CONSEQUENTIAL,   EXEMPLARY,
                    INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE,
                    LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN
                    IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
                    ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
                    CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF ACTION, WILL AT ALL TIMES
                    BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY BY YOU TO US OR CERTAIN
                    NIGERIA   STATE   LAWS   AND   INTERNATIONAL   LAWS   DO   NOT   ALLOW   LIMITATION   OF
                    CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                    DISCLAIMERS OR IMITATIONS MAY NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL
                    RIGHTS.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• INDEMNIFICATION</h2>
                    You   agree   to   defend,   indemnify   and   hold   us   harmless,   including   our   subsidiaries,
                    affiliates, and all of our respective officers, agents, partners, and employees, form and
                    against any loss, damage, liability, claim, or demand, including reasonable attorneys’
                    fees and expenses, made by any third party due to or arising out of: (a) use of the
                    services;   (b)   breach   of   these   Legal   Terms   of   Service;   (c)   any   breach   of   your
                    representations and warranties set forth in these Legal Terms of Service;  (d) your
                    violation of the rights of a third party, including but not limited to intellectual property
                    rights; or (e) any overt harmful act toward any other user of the services with whom you
                    connected via the services. Notwithstanding the foregoing, we reserve the right, at your
                    expense, to assume the exclusive defence and control of the matter for which you are
                    required to indemnify us, and you agree to cooperate, at your expense, with our defence
                    of such claims. We will use reasonable efforts to notify you of any such claim, action, or
                    proceeding which is subject to tis indemnification upon becoming aware of it.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• USER DATA</h2>
                    We will maintain certain data that you transmit to the Services for the purpose of
                    managing the performance of the services, as well as data relating to your use of the
                    services.   Although   we   perform   regular   routine   backups   of   data,   you   are   solely
                    responsible for all data that you transmit or that relates to any activity you have
                    undertaken using the Services. You agree that we shall have no liability to you for any
                    loss or corruption of any such data, and you hereby waive any right of action against us
                    arising from any such loss or corruption of such data.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• ELECTRONIC, COMMUNICATION, TRANSACTION, AND SIGNATURES</h2>
                    Visiting   the   services,   sending   us   e-mails,   and   completing   online   forms   constitute
                    electronic communication. You consent to receive electronic communications, and you
                    agree that all agreements, notices, disclosures and other communications we provide to
                    you electronically, via email and on the services, satisfy any legal requirement that such
                    communication   be   in   writing.   YOU   HEREBY   AGREE   TO   THE   USE   OF   ELECTRONIC
                    SIGNATURES,   CONTRACTS,   ORDERS,   AND   OTHER   RECORDS,   AND   TO   ELECTRONIC
                    DELIVERY   OF   NOTICES,   POLICIES   AND   RECORDS   OF   TRANSACTIONS   INITIATED   OR
                    COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements
                    under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which
                    require   original   signature   or   delivery   or   retention   of   non-electronic   records,   or   to
                    payments or the granting of credits by any means other than electronic means.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• MISCELLANEOUS </h2>
                    These Legal terms and any policies or operating rules posted by us on the services or in
                    respect to the services constitute the entire agreement and understanding between you
                    and us. Our failure to exercise or enforce any right or provision of these Legal Terms of
                    Service shall not operate as a waiver  of such right or provision. These Legal Terms of
                    Service operate to the fullest extent permissible by Law. We may assign any or all of our
                    rights and obligation stop others at any time. We shall not be responsible or liable for
                    any loss, damage, delay, or failure to act caused by any cause beyond our reasonable
                    control. If any provision or part of the provision of these legal terms of service is
                    determined to be unlawful. Void, or unenforceable that provision is deemed severable
                    from these Legal Terms of Service and does not affect the validity and enforceability of
                    any remaining provisions. There is no joint venture, partnership, employment, or agency
                    relationship, created between you and us as a result of these Legal terms of service or
                    use of service. You agree that Legal Terms will not be construed against us by virtue of
                    having drafted them. You hereby waive any and all defenses you may have based on the
                    electronic form of these Legal Terms of Service and the lack of signing by the parties
                    hereto to execute these Legal Terms of Service.
                </span>
                <span className='flex flex-col gap-y-1'>
                    <h2>• CONTACT US</h2>
                    In order to resolve a complaint regarding the services or to receive further information
                    regarding the use of the service, please contact us at:
                    Contact Address- No 2 Onikoyi Road, Ikoyi, Lagos, Nigeria
                    Email-
                </span>
            </div>

        </div>
    )
}

export default Terms