import React from 'react'
import HeroPage from './HeroPage'
import "@fontsource/poppins";
import FlexiblePage from './FlexiblePage';
import HowItWorks from './HowItWorks';
// import Modal from '../modal/Modal';

const LandingPage = () => {
  return (
    <div>
      <head>
        <title> Rent Properties with Ease and pay monthly </title>
      </head>
        {/* <Modal/> */}
        <HeroPage/>
        <FlexiblePage/>
        <HowItWorks/>
    </div>
  )
}

export default LandingPage