import React from "react";
import logo from "../img/logo.png";
import { MdMenu } from "react-icons/md";
import { Link } from "react-router-dom";
import { useState } from "react";
import { BsX } from "react-icons/bs";
const NavBar = () => {
  const [expand, setExpand] = useState(false);
  let iconStyle = { color: "#287BDC", marginTop: "6px", marginRight: "0px", marginLeft: "17px", cursor: "pointer" };
  return (
    <div className='sticky'>
      <div className="w-full flex items-center px-14 flex-row mt-2 max-lg:hidden">
        <div className="w-[195px]">
          <Link to="/">
            <img src={logo} className="" alt="logo"></img>
          </Link>
        </div>
        <div className="w-[60%]">
          <ul className="flex justify-evenly">
            <Link to="/">
              <li className=" text-[#6E6E74] font-semibold text-[16px] cursor-pointer hover:border-b-2 hover:border-blue-600 ease-in-out duration-150">
                Home
              </li>
            </Link>
            <a target="_blank" href="https://agent.myrentease.co/" rel="noreferrer" >
              <li className=" text-[#6E6E74] font-semibold text-[16px] cursor-pointer hover:border-b-2 hover:border-blue-600 ease-in-out duration-150">
                Become an Agent
              </li>
            </a>
            {/* <a target="_blank" href="https://quickshelter.ng/"><li className=' text-[#6E6E74] font-semibold text-[16px] cursor-pointer hover:border-b-2 hover:border-blue-600 ease-in-out duration-150'>Become a Home owner</li></a> */}
            <a target="_blank" href="https://explorer.myrentease.co/" rel="noreferrer" >
              <li className=" text-[#6E6E74] font-semibold text-[16px] cursor-pointer hover:border-b-2 hover:border-blue-600 ease-in-out duration-150">
                Properties
              </li>
            </a>
            <Link to="/faq">
              <li className=" text-[#6E6E74] font-semibold text-[16px] cursor-pointer hover:border-b-2 hover:border-blue-600 ease-in-out duration-150">
                FAQ
              </li>
            </Link>
          </ul>
        </div>
        <div className="flex justify-evenly">
          <div className=" w-[50%] py-2">
            <a target="_blank" href="https://account.myrentease.co/login" rel="noreferrer" >
              <button className=" border-2 rounded-md bg-[#287BDC] text-center border-transparent h-[50px] w-[152px] text-white cursor-pointer">
                Log In
              </button>
            </a>
          </div>
          <div className=" w-[40%] py-2">
            <a target="_blank" href="https://account.myrentease.co/" rel="noreferrer" >
              <button className=" border-2 rounded-md bg-[#FFFFFF] text-center h-[50px] w-[152px] text-[#6E6E74] cursor-pointer">
                Apply Now
              </button>
            </a>
          </div>
        </div>
      </div>
      {/*mobile view*/}
      <div className="w-full flex flex-row mt-2 lg:hidden h-auto px-4 py-3 justify-between">
        <div className="w-[130px] h-auto">
          <Link to="/">
            <img src={logo} className="" alt="logo"></img>
          </Link>
        </div>
        {/* login and menu*/}
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div className=" w-[90.85px] h-[35.96px] py-2">
              <a target="_blank" href="https://account.myrentease.co/login" rel="noreferrer" >
                <button className=" border-2 rounded-md bg-[#287BDC] max-lg:w-[100.85px] max-lg:h-[40.96px] text-center border-transparent text-white cursor-pointer">
                  Log In
                </button>
              </a>
            </div>
            {/*menu*/}
            <div className="flex flex-row w-auto relative">
              <div>
                <button aria-label="dropdown-menu" onClick={() => setExpand(!expand)}>
                  {expand ? <BsX color="#287BDC" style={iconStyle} size={38} height="auto" /> : <MdMenu style={iconStyle} size={38} height="auto" />}
                </button>
              </div>
            </div>
            {/*dropdown*/}
          </div>
        </div>
      </div>
      {expand && (
        <div className="lg:hidden absolute w-full bg-white grid justify-center z-[10] opacity-[0.9] h-[100vh] py-[27%] transition ease-in duration-[4000] delay-500 max-md:py-[15%] max-md:h-auto max-md:leading-[50px] max-lg:py-[15%] max-lg:leading-[50px] slide">
          <ul className=" text-center grid justify-center">
            <a target="_blank" href="https://agent.myrentease.co/" rel="noreferrer" >
              <li className="pt-5 text-[#000000] font-semibold text-[16px] cursor-pointer hover:border-b-2 hover:border-blue-600 ease-in-out duration-150">
                Become an Agent
              </li>
            </a>
            {/* <a target="_blank" href="https://quickshelter.ng/"><li className=' text-[#000000] font-semibold text-[16px] cursor-pointer hover:border-b-2 hover:border-blue-600 ease-in-out duration-150'>Become a Home owner</li></a> */}
            <a target="_blank" href="https://explorer.myrentease.co/" rel="noreferrer" >
              <li className=" text-[#000000] font-semibold text-[16px] cursor-pointer hover:border-b-2 hover:border-blue-600 ease-in-out duration-150">
                Properties
              </li>
            </a>
            <Link to="/faq">
              <li className=" text-[#000000] font-semibold text-[16px] cursor-pointer hover:border-b-2 hover:border-blue-600 ease-in-out duration-150">
                FAQ
              </li>
            </Link>
            <div className=" w-[100%] py-2 flex justify-center">
              <a target="_blank" href="https://account.myrentease.co/" rel="noreferrer" >
                <button className=" border-2 rounded-md bg-[#FFFFFF] h-[50px] text-center w-[152px] text-[#000000] cursor-pointer">
                  Apply
                </button>
              </a>
            </div>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavBar;
