import React from 'react'

const Cookie = () => {
    return (
        <div className='grid gap-y-7 pt-5'>
            <head>
                <title>Cookie Policy</title>
            </head>
            <h1 className='flex justify-center font-bold text-[35px]'>Cookie Policy</h1>
            {/* <h4 className='px-14 leading-relaxed opacity-50 flex flex-col'>
            </h4> */}
            <div className='px-14 grid gap-y-3'>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>What are Cookies?</h2>
                    <p className='opacity-50'>Cookies are messages or small files that are placed on your web browser when you visit an
                        internet site. Cookies are useful and do a lot of different things, including allowing a website to
                        recognise a user’s device, improving online experience etc.</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>How and why do we use Cookies?</h2>
                    <p className='opacity-50'>We use cookies when you access our websites and our online products and services in order to
                        make the site work better and to help us understand how you use our site.
                        Cookies enable us to offer tailored products and to understand the information we receive about
                        you, including information about your use of other websites and apps, whether or not you have
                        an account with us.
                        Cookies help us provide, protect and improve our products and services, by personalising,
                        tailoring and measuring the services and products we provide for satisfactory and safe
                        experience. We also use them to help user authentication, assess performance and functionality
                        of our online products and services, and conduct analytical research.
                        This Cookies Policy (Policy) explains how we use cookies and the choices you have. Except as
                        otherwise stated in this Policy, our Privacy Policy will apply to our processing of data that we
                        collect via cookies. You can access our Privacy Policy via this       HYPERLINK
                        "https://www.unionbankng.com/privacy-notice/"link.
                        By using our website (by clicking, navigating or scrolling), you are consenting to our use of
                        cookies in accordance with this Policy. If you do not agree to our use of cookies, you can
                        prevent the installation of cookies through the settings of your browser or you may choose not to
                        use our website at all. Please note that disabling the use of cookies might cause some parts of
                        this website not to function properly for you and it may impact your user experience on this site.</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>What type of cookies does My rentease use?</h2>
                    <p className='opacity-50'>Cookies can either be session cookies or persistent cookies. A session cookie expires
                        automatically when you close your browser. A persistent cookie will remain until it expires or you
                        delete your cookies. Expiration dates are set in the cookies themselves; some may expire after
                        a few minutes while others may expire after multiple years. Cookies placed by the website
                        you’re visiting are called “first party cookies”.
                        Strictly Necessary cookies are necessary for our website to function and cannot be switched off
                        in our systems. They are essential in order to enable you to navigate around the website and
                        use its features. If you remove or disable these cookies, we cannot guarantee that you will be
                        able to use our website.
                        We use the following types of cookies in our website:</p>

                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Essential Cookies</h2>
                    <p className='opacity-50'>We use essential cookies to make our website work. These cookies are strictly necessary to
                        enable core functionality such as security, network management, your cookie preferences and
                        accessibility. Without them you wouldn’t be able to use basic services. You may disable these
                        by changing your browser settings, but this may affect how the Websites function.</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>How you can manage cookies?</h2>
                    <p className='opacity-50'>Most browsers allow you to control cookies through their ‘settings’ preferences. However, if you
                        limit the ability of websites to set cookies, you may worsen your overall user experience, since it
                        will no longer be personalized to you. It may also stop you from saving customized settings like
                        login information. Browser manufacturers provide help pages relating to cookie management in
                        their products.
                        Browser manufacturers provide help pages relating to cookie management in their products.
                        Please see below for more information.</p>
                    <ul className='flex flex-col gap-y-2 opacity-50'>
                        <li><h4>• Google Chrome</h4></li>
                        <li><h4>• Internet Explorer</h4></li>
                        <li><h4>• Mozilla Firefox</h4></li>
                        <li><h4>• Safari (Desktop)</h4></li>
                        <li><h4>• • Safari (Mobile)</h4></li>
                        <li><h4>• Android Browser</h4></li>
                        <li><h4>• Opera</h4></li>
                        <li><h4>• Opera Mobile</h4></li>
                    </ul>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Blocking and disabling cookies and similar technologies</h2>
                    <p className='opacity-50'>Wherever you’re located you may also set your browser to block cookies and similar
                        technologies, but this action may block our essential cookies and prevent our website from
                        functioning properly, and you may not be able to fully utilize all of its features and services. You
                        should also be aware that you may also lose some saved information (e.g. saved login details,
                        site preferences) if you block cookies on your browser. Different browsers make different
                        controls available to you. Disabling a cookie or category of cookie does not delete the cookie
                        from your browser, you will need to do this yourself from within your browser, you should visit
                        your browser’s help menu for more information</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Changes To Our Cookie Policy</h2>
                    <p className='opacity-50'>We may change our Service and policies, and we may need to make changes to this Cookie
                        Policy so that they accurately reflect our Service and policies. Unless otherwise required by law,
                        we will notify you (for example, through our Service) before we make changes to this Cookie
                        Policy and give you an opportunity to review them before they go into effect. Then, if you
                        continue to use the Service, you will be bound by the updated Cookie Policy. If you do not want
                        to agree to this or any updated Cookie Policy, you can delete your account</p>
                </span>
                <span className='flex flex-col gap-y-2'>
                    <h2 className=' font-bold text-[25px] opacity-100'>Your Consent</h2>
                    <p className='opacity-50'>By using our website, registering an account, or making a purchase, you hereby consent to our
                        Cookie Policy and agree to its terms.</p>
                </span>
            </div>
        </div>
    )
}

export default Cookie