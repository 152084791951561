import React from 'react'
import "@fontsource/poppins";
import { GoPlus } from "react-icons/go";
import { AiOutlineMinus } from "react-icons/ai";
import { useState } from 'react';
import HowItWorks from '../landing/HowItWorks';
const FaqPage = () => {
    let iconStyle = {cursor:'pointer'};
    const [expand,setExpand] = useState(null)
 

    
    const data =[   
      //  {
      //     question: 'What is the purpose of this website?',
      //     answer: 'This website was created to help people who are new in coding and want a platform where they can learn how to code. It also helps them',
      //    },
         {
         question: 'What is Rentease ?',
         answer: 'We operate as a monthly rental platform, providing customers with the flexibility to select from a variety of verified properties listed by our field agents. Additionally, customers have the option to upload properties of their preference. Our service assists in alleviating the annual rental expenses for these properties, offering them for monthly rentals to customers at a manageable cost.',
      },
      {
         question: 'Who is Rentease for ?',
         answer: 'Individuals with fixed salaries who find it challenging to cover the annual rental expenses in their desired location',
      },
      {
         question: 'Can i use Rentease to renew my rent ?',
         answer: 'Yes, you can, but we will require the tenancy agreement from your previous rental.',
      },
      {
         question: 'How can i be eligible to to rent a property and pay installmentally ?',
         answer: 'To fulfill our KYC (Know Your Customer) and current employment requirements, you will be asked to provide the following documentation: a valid ID card, details of your next of kin, and proof of your current address. In terms of your employment, we will require your employment letter, the name of your employer, and the last six months" worth of bank statements',
      },
      {
         question: 'Can i pay at once if i have my rent already ?',
         answer: 'At present, this option is not available; Rentease is exclusively offered to clients who opt for monthly rentals',
      },
      {
         question: 'Will Utility and Security bills be part of what Rentease covers for me ?',
         answer: 'We do not include utility bills and other ongoing monthly apartment expenses in our coverage. However, if there is a set annual service fee, we will include it in our coverage',
      },
      {
         question: 'How much do i need to pay upfront ?',
         answer: 'For new tenants, you make an initial payment of 35% of your rent, with the remaining 65% being divided into 10 monthly installments',
      },
      {
         question: 'How is the repayment collected ?',
         answer: 'We will automatically deduct a set amount from your bank account on a monthly basis',
      },
      {
         question: 'Do i get a Tenancy agreement after my rent process is complete ?',
         answer: 'Yes you will',
      },
      {
         question: 'Do you give out loans ?',
         answer: 'Yes, we give out personal loans to salary earners',
      },
   ]
   const toogle = (i) =>{
      if (expand === i) {
         return setExpand(null)
      }
      setExpand(i)
   }
  return (
    <div>
      <head>
         <title>FAQ</title>
      </head>
        <div className='w-full max-lg:hidden my-7'>
             <h1 className=' text-center text-[30px] text-[#000000] font-bold'>Frequently Asked Questions</h1>
             <p className=' text-center text-[22px] text-[#000000] mt-3'>Here are some frequently asked questions</p>
         </div>
         <div className='bg-[url("components/img/bg.png")] px-[30%] max-lg:hidden'>
           <div className='bg-[url("components/img/circles.png")] bg-no-repeat bg-contain bg-center relative block justify-center align-middle h-auto py-[20%]'>
            <div className=''>
               {data.map((item, i) =>(
                  <div className='flex flex-col gap-y-2'>
                     <div className='mt-2'>
                        <div className=' py-5 bg-white flex justify-between w-full px-6'>
                           <h1 className=' text-black'>{item.question}</h1>
                           <button aria-label='question-expander' onClick={() => toogle(i)}>
                              {
                                 expand === i ?
                                 <AiOutlineMinus color='#287BDC' style={iconStyle}/>
                                 :
                                 <GoPlus color='#287BDC' style={iconStyle}/>
                              }
                           </button>
                        </div>
                        <div className='bg-white'>
                           <div className={expand === i ?'py-5 justify-between w-full px-6 flex transition duration-1000 cubico':'py-5 justify-between w-full px-6 hidden transition duration-1000 cubic'}>
                              <p>{item.answer}</p>
                           </div>
                        </div>  
                     </div>
                  </div>
               ))}
            </div>
             {/* <div className='grid'>
                 <div className=' py-5 bg-white flex justify-between w-full px-6'>
                     <h1 className=' text-black'>Lorem ipsum dolor sit amet, consectetur adipiscing el</h1>
                     <button aria-label='question-expander' onClick={() => setExpand(!expand)}>
                        {
                            expand ?
                           <AiOutlineMinus color='#287BDC' style={iconStyle}/>
                            :
                             <GoPlus color='#287BDC' style={iconStyle}/>
                        }
                     </button>
                 </div>
                 <div className={ansClass}>
                    <div className='py-5 bg-white justify-between w-full mt-3 px-6'>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed<br></br> do eiusmod tempor incididunt ut labore et dolore magna<br></br> aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                    </div>
                 </div>
                 <div className=' py-5 bg-white flex justify-between w-full px-6 mt-5'>
                     <h1 className=' text-black'>Lorem ipsum dolor sit amet, consectetur adipiscing el</h1>
                     <button aria-label='question-expander' onClick={() => setExpand(!expand)}>
                        {
                            expand ?
                           <AiOutlineMinus color='#287BDC' style={iconStyle}/>
                            :
                             <GoPlus color='#287BDC' style={iconStyle}/>
                        }
                     </button>
                 </div>
                 <div className={ansClass}>
                    <div className='py-5 bg-white justify-between w-full mt-3 px-6'>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed<br></br> do eiusmod tempor incididunt ut labore et dolore magna<br></br> aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                    </div>
                 </div>
                 <div className=' py-5 bg-white flex justify-between w-full px-6 mt-5'>
                     <h1 className=' text-black'>Lorem ipsum dolor sit amet, consectetur adipiscing el</h1>
                     <button aria-label='question-expander' onClick={() => setExpand(!expand)}>
                        {
                            expand ?
                           <AiOutlineMinus color='#287BDC' style={iconStyle}/>
                            :
                             <GoPlus color='#287BDC' style={iconStyle}/>
                        }
                     </button>
                 </div>
                 <div className={ansClass}>
                    <div className='py-5 bg-white justify-between w-full mt-3 px-6'>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed<br></br> do eiusmod tempor incididunt ut labore et dolore magna<br></br> aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                    </div>
                 </div>
                 <div className=' py-5 bg-white flex justify-between w-full px-6 mt-5'>
                     <h1 className=' text-black'>Lorem ipsum dolor sit amet, consectetur adipiscing el</h1>
                     <button aria-label='question-expander' onClick={() => setExpand(!expand)}>
                        {
                            expand ?
                           <AiOutlineMinus color='#287BDC' style={iconStyle}/>
                            :
                             <GoPlus color='#287BDC' style={iconStyle}/>
                        }
                     </button>
                 </div>
                 <div className={ansClass}>
                    <div className='py-5 bg-white justify-between w-full mt-3 px-6'>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed<br></br> do eiusmod tempor incididunt ut labore et dolore magna<br></br> aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                    </div>
                 </div>
             </div> */}
           </div>
         </div>
         {/*mobile view */}
         <div className='w-full lg:hidden my-9'>
             <h1 className=' text-center text-[26px] text-[#000000] font-bold'>Frequently Asked Questions</h1>
             <p className=' text-center text-[20px] text-[#000000] mt-3'>Here are some frequently<br></br> asked questions</p>
         </div>
         <div className='bg-[url("components/img/bg.png")] px-3 lg:hidden'>
           <div className='bg-[url("components/img/circles.png")] bg-no-repeat bg-contain bg-center relative block justify-center align-middle h-auto py-[20%]'>
           <div className=''>
               {data.map((item, i) =>(
                  <div className='flex flex-col gap-y-2'>
                     <div className='mt-2'>
                        <div className=' py-5 bg-white flex justify-between w-full px-6'>
                           <h1 className=' text-black'>{item.question}</h1>
                           <button aria-label='question-expander' onClick={() => toogle(i)}>
                              {
                                 expand === i ?
                                 <AiOutlineMinus color='#287BDC' style={iconStyle}/>
                                 :
                                 <GoPlus color='#287BDC' style={iconStyle}/>
                              }
                           </button>
                        </div>
                        <div className='bg-white'>
                           <div className={expand === i ?'py-5 justify-between w-full px-6 flex transition duration-1000 cubico':'py-5 justify-between w-full px-6 hidden transition duration-1000 cubic'}>
                              <p>{item.answer}</p>
                           </div>
                        </div>  
                     </div>
                  </div>
               ))}
            </div>
           </div>
         </div>
         <div>
            <HowItWorks/>
         </div>
    </div>
  )
}

export default FaqPage
